import _ from "lodash";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    states: [],
    code_and_conditions: [],
    ahj_data: [],
    ahj_meta: {},
    selected_ahj_id: null,
    show_import_excel_csv_dialog: false,
    search_text: "",
    fetching_data: false,
    filters: {
      county: null,
      state: null,
      city: null
    }
  },
  getters: {
    is_filters_has_value: state => {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(state.filters)) {
        if (value) {
          return true;
        }
      }

      return false;
    }
  },
  mutations: {
    SET_CODE_AND_CONDITIONS: (state, payload) =>
      (state.code_and_conditions = payload),
    SET_STATES: (state, payload) => (state.states = payload),
    SET_AHJ_DATAS: (state, payload) => {
      state.ahj_data = payload.data;
      state.ahj_meta = payload.meta;
      state.fetching_data = false;
    },
    SET_SEARCH_TEXT: (state, payload) => (state.search_text = payload),
    SET_FETCHING_DATA: (state, payload) => (state.fetching_data = payload),
    SET_FILTERS: (state, payload) => (state.filters = payload),
    CLEAR_FILTERS_SEARCH: state => {
      state.term = "";
      state.filters = {
        county: null,
        state: null,
        city: null
      };
    }
  },
  actions: {
    getAhj: async ({ state, commit }, page) => {
      commit("SET_FETCHING_DATA", true);

      const params = {
        page: page,
        ...state.filters,
        term: state.search_text
      };

      const result = await api.get("/ahj", { params });
      const data = _.get(result, "data.result", []);

      commit("SET_AHJ_DATAS", data);
    },
    getStates: async ({ commit }) => {
      const result = await api.get("/providers/states");
      const data = _.get(result, "data.result", []);
      commit("SET_STATES", data);
    },
    getCodeAndConditions: async ({ commit }) => {
      const result = await api.get("/providers/codes-and-conditions");
      const data = _.get(result, "data.result", []);
      commit("SET_CODE_AND_CONDITIONS", data);
    },
    getLocations: async payload => {
      const params = _.get(payload, "params", {});
      return await api.get("/locations", { params });
    }
  }
};
